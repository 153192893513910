import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
import PrimerParcial from "@/components/layout/CuadernoSeguimiento/JefeCarrera/PrimerParcial.vue";
import SegundoParcial from "@/components/layout/CuadernoSeguimiento/JefeCarrera/SegundoParcial.vue";
import ExamenOptativo from "@/components/layout/CuadernoSeguimiento/JefeCarrera/ExamenOptativo.vue";
import TercerParcial from "@/components/layout/CuadernoSeguimiento/JefeCarrera/TercerParcial.vue";
import ExamenSegundoTurno from "@/components/layout/CuadernoSeguimiento/JefeCarrera/ExamenSegundoTurno.vue";
import Resumen from "@/components/layout/CuadernoSeguimiento/JefeCarrera/Resumen.vue";
window.$ = window.jQuery = require("jquery");

export default {
    name: "RolPage",
    data() {
        let ss = new MainService();
        return {
            msg: "RolPage",
            ss: ss,
            ajax: {
                "url": ss.indexJefeCarreraPlanesTrabajo(),
                headers: ss.getToken(),
                data: function (d) {
                    d.idEspecialidad = 0;
                    d.idUnidadAcademica = 0;
                    }
            },
            columns: [
                { data: 'idMateria', name: 'idMateria', orderable: false, searchable: false, visible:false},
                { data: 'idCurso', name: 'idCurso', orderable: false, searchable: false, visible: false },
                { data: 'idDocente', name: 'idDocente', orderable: false, searchable: false, visible: false },
                { data: 'Dicta', name: 'Dicta', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Especialidad', name: 'Especialidad', title: 'Especialidad' },
                { data: 'Materia', name: 'Materia', title: 'Materia' },
                { data: 'Sigla', name: 'Sigla', title: 'Sigla' },
                { data: 'Docente', name: 'Docente', title: 'Docente' },
                { data: 'Dicta', name: 'Dicta', title: 'Dicta' },
                { data: 'Curso', name: 'Curso', title: 'Curso' },
                { data: 'PeriodoGestion', name: 'PeriodoGestion', title: 'Periodo' },
                { data: 'Gestion', name: 'Gestion', title: 'Gestion' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            rols: [],
            plan: {},
            isLoading: false,
            showPlan: false,
            errorBag: {},
            auth: {},
            busquedaSaga:{},
            especialidadesSAGA:[],
            unidadAcademicas:[],
            aprobado:0,
            parcial:{},
            layout : 'PrimerParcial'
        };
    },
    methods: {
       
        
        async getPlanTrabajo(){
            console.log(this.auth);
            let idEspecialidad = this.auth.idEspecialidad;
            let idUnidadAcademica = this.auth.UnidadAcademica;
            console.log(idEspecialidad);
            console.log(idUnidadAcademica);
            this.ajax.data = function(d){
                d.idEspecialidad = idEspecialidad;
                d.idUnidadAcademica = idUnidadAcademica;
            }
           await new Promise(resolve => setTimeout(resolve, 2000));
            this.$refs['datatable-PlanTrabajo'].reloadAjax(this.ajax);
       },



       showMateriaDocente(planTrabajo){
        this.isLoading=true;
        this.ss.showMateriaDocente(planTrabajo).then(
            (result) => {
                let response = result.data;
                this.plan = response[0];
                this.infGeneral = this.plan;
                console.log(this.plan);
                this.$refs['view-cuaderno-seguimiento'].show();
                this.isLoading=false;
            }
        ).catch(error => {
            console.log(error);
            this.isLoading=false;
        });
    },

    getEspecialidades(){
        this.ss.listEspecialidad().then(
           (result) => {
               let response = result.data;
               console.log(response);
               this.especialidadesSAGA = response;
           }
       ).catch(error => {
           console.log(error);
       });
    },

    getUnidadAcademica() {
        this.ss.listUnidadAcademica().then(
            (result) => {
                let response = result.data;
                this.unidadAcademicas = response.data;
            }
        ).catch(error => {
            console.log(error);
        });
    },

    async BusquedaSaga(){
        this.isLoading=true;
        console.log(this.busquedaSaga);
        let idEspecialidad = this.busquedaSaga.idEspecialidad;
        let idUnidadAcademica = this.busquedaSaga.idUnidadAcademica;
        this.ajax.data = function(d){
            d.idEspecialidad = idEspecialidad;
            d.idUnidadAcademica = idUnidadAcademica;
        }

        if(this.auth.Rol == 1){
            let iddoc = 0;
            this.ajax.data = function(d){
                d.idDocente = iddoc;
                d.idEspecialidad = idEspecialidad;
                d.idUnidadAcademica = idUnidadAcademica;
            }
        }else{
            let iddoc = 0;
            this.ajax.data = function(d){
                d.idDocente = iddoc;
                d.idEspecialidad = idEspecialidad;
                d.idUnidadAcademica = idUnidadAcademica;
            }
        }

        this.$refs['datatable-PlanTrabajo'].reloadAjax(this.ajax); 
        this.isLoading=false;
    },

    seleccionMenu(value){
        this.layout = value;
        this.parcial=value;
        console.log(this.layout);
        this.infGeneral = this.plan;
    },

        draw() {
            window.$('.btn-datatable-PlanTrabajo').on('click', (evt) => {
                this.planTrabajo = {};
                this.planTrabajo.idMateria = window.$(evt.target)[0].getAttribute('idMateria');
                this.planTrabajo.idCurso = window.$(evt.target)[0].getAttribute('idCurso');
                this.planTrabajo.idDocente = window.$(evt.target)[0].getAttribute('idDocente');
                this.planTrabajo.Dicta = window.$(evt.target)[0].getAttribute('Dicta');

                if(this.planTrabajo.idMateria == null){
                    this.$swal.fire(
                        'Oops...',
                        'Por favor intente nuevamente.',
                        'error'
                      )
                    return;
                }
                else{
                    this.showMateriaDocente(this.planTrabajo);
                }
            });
        }
    },
    components: {
        dataTable,
        Loader,
        PrimerParcial,
        SegundoParcial,
        ExamenOptativo,
        TercerParcial,
        ExamenSegundoTurno,
        Resumen
    },
    mounted() {
        var usuario = JSON.parse(localStorage.getItem('usuario'));
        if (!usuario) {
            this.$router.push('/Login');
        }
        this.auth=usuario;
        this.getPlanTrabajo();
        this.getEspecialidades();      
        this.getUnidadAcademica();
    }
};
