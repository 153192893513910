import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
import PlanTrabajo from "@/components/layout/PlanTrabajo/PlanTrabajo.vue";
window.$ = window.jQuery = require("jquery");

export default {
    name: "RolPage",
    data() {
        let ss = new MainService();
        return {
            msg: "RolPage",
            ss: ss,
            ajax: {
                "url": ss.indexJefeCarreraPlanesTrabajo(),
                headers: ss.getToken(),
                data: function (d) {
                    d.idEspecialidad = 0;
                    d.idUnidadAcademica = 0;
                    }
            },
            columns: [
                { data: 'idMateria', name: 'idMateria', orderable: false, searchable: false, visible:false},
                { data: 'idCurso', name: 'idCurso', orderable: false, searchable: false, visible: false },
                { data: 'idDocente', name: 'idDocente', orderable: false, searchable: false, visible: false },
                { data: 'Dicta', name: 'Dicta', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Especialidad', name: 'Especialidad', title: 'Especialidad' },
                { data: 'Materia', name: 'Materia', title: 'Materia' },
                { data: 'Sigla', name: 'Sigla', title: 'Sigla' },
                { data: 'Docente', name: 'Docente', title: 'Docente' },
                { data: 'Dicta', name: 'Dicta', title: 'Dicta' },
                { data: 'Curso', name: 'Curso', title: 'Curso' },
                { data: 'PeriodoGestion', name: 'PeriodoGestion', title: 'Periodo' },
                { data: 'Gestion', name: 'Gestion', title: 'Gestion' },
                { data: 'PlanTrabajo', name: 'PlanTrabajo', title: 'Estado',
                render:function(data, type, row){
                    
                    
                    if(row.PlanTrabajo == 0 || row.PlanTrabajo == null){
                        return  '<div class="text-center font-weight-bold"><p class="text-primary mb-0"> NO ENTREGADO <b> </b></p></div>';
                    }

                    if(row.PlanTrabajo == 1){
                        return  '<div class=" text-center font-weight-bold"><p class="text-warning mb-0"> PENDIENTE <b> </b></p></div>';
                    }

                    if(row.PlanTrabajo == 2){
                        return  '<div class=" text-center font-weight-bold"><p class="text-success mb-0"> APROBADO <b> </b></p></div>';
                    }

                    if(row.PlanTrabajo == 3){
                        return  '<div class=" text-center font-weight-bold"><p class="text-danger mb-0"> RECHAZADO <b> </b></p></div>';
                    }




                    }
                },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            rols: [],
            plan: {},
            isLoading: false,
            showPlan: false,
            errorBag: {},
            auth: {},
            busquedaSaga:{},
            especialidadesSAGA:[],
            unidadAcademicas:[],
            aprobado:0
        };
    },
    methods: {
       
        deleteRol() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyRol(this.rol)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-rol'].hide();
                                this.$refs['datatable-rol'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        async getPlanTrabajo(){
            console.log(this.auth);
            let idEspecialidad = this.auth.idEspecialidad;
            let idUnidadAcademica = this.auth.UnidadAcademica;
            console.log(idEspecialidad);
            console.log(idUnidadAcademica);
            this.ajax.data = function(d){
                d.idEspecialidad = idEspecialidad;
                d.idUnidadAcademica = idUnidadAcademica;
            }
            if(this.auth.Rol == 1){
                let iddoc = 0;
                this.ajax.data = function(d){
                    d.idDocente = iddoc;
                    d.idEspecialidad = idEspecialidad;
                    d.idUnidadAcademica = idUnidadAcademica;
                }
            }else{
                let iddoc = 0;
                this.ajax.data = function(d){
                    d.idDocente = iddoc;
                    d.idEspecialidad = idEspecialidad;
                    d.idUnidadAcademica = idUnidadAcademica;
                }
            }
           await new Promise(resolve => setTimeout(resolve, 2000));
            this.$refs['datatable-PlanTrabajo'].reloadAjax(this.ajax);
       },

       showPlanTrabajo(){
        this.showPlan = true;
        this.$refs['view-plan-trabajo'].hide();
       },

       goBackPlanTrabajo(){
            this.showPlan=false;
        },

       showMateriaDocente(planTrabajo){
        this.isLoading=true;
        this.ss.showMateriaDocente(planTrabajo).then(
            (result) => {
                let response = result.data;
                this.plan = response[0];
                console.log(this.plan);
                this.$refs['view-plan-trabajo'].show();
                this.isLoading=false;
            }
        ).catch(error => {
            console.log(error);
            this.isLoading=false;
        });
    },

    getEspecialidades(){
        this.ss.listEspecialidad().then(
           (result) => {
               let response = result.data;
               console.log(response);
               this.especialidadesSAGA = response;
           }
       ).catch(error => {
           console.log(error);
       });
    },

    getUnidadAcademica() {
        this.ss.listUnidadAcademica().then(
            (result) => {
                let response = result.data;
                this.unidadAcademicas = response.data;
            }
        ).catch(error => {
            console.log(error);
        });
    },

    async BusquedaSaga(){
        this.isLoading=true;
        console.log(this.busquedaSaga);
        let idEspecialidad = this.busquedaSaga.idEspecialidad;
        let idUnidadAcademica = this.busquedaSaga.idUnidadAcademica;
        this.ajax.data = function(d){
            d.idEspecialidad = idEspecialidad;
            d.idUnidadAcademica = idUnidadAcademica;
        }

        this.$refs['datatable-PlanTrabajo'].reloadAjax(this.ajax); 
        this.isLoading=false;
    },

    savePlanTrabajoEstado(estado){

        if(this.plan.PlanTrabajo == 0 || this.plan.PlanTrabajo == null){
            this.$swal.fire({
                title: "Advertencia!",
                text: "El Plan de Trabajo no fue entregado por el docente!.",
                icon: "warning"
              });
              return;
        }

        this.plan.estado = estado;
        this.ss.storeMateriaDocente(this.plan).then(
            (result) => {
                let response = result;
                console.log(response.data);
                if(this.plan.estado == 2){
                    this.$swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Plan de Trabajo Aprobado",
                        showConfirmButton: false,
                        timer: 1500
                      });

                      this.ss.cargarPlanTrabajo(this.plan).then(
                        (result) => {
                            let response = result;
                            console.log(response.data);
                        }
                    ).catch(error => {
                        console.log(error);
                        this.isLoading=false;
                    });

                }
                if(this.plan.estado == 3){
                    this.$swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Plan de Trabajo Rechazado",
                        showConfirmButton: false,
                        timer: 1500
                      });
                }
                this.$refs['view-plan-trabajo'].hide();
                this.$refs['datatable-PlanTrabajo'].reload();
            }
        ).catch(error => {
            console.log(error);
            this.isLoading=false;
        }); 
    
    },


        draw() {
            window.$('.btn-datatable-PlanTrabajo').on('click', (evt) => {
                this.planTrabajo = {};
                this.planTrabajo.idMateria = window.$(evt.target)[0].getAttribute('idMateria');
                this.planTrabajo.idCurso = window.$(evt.target)[0].getAttribute('idCurso');
                this.planTrabajo.idDocente = window.$(evt.target)[0].getAttribute('idDocente');
                this.planTrabajo.Dicta = window.$(evt.target)[0].getAttribute('Dicta');
                this.aprobado = window.$(evt.target)[0].getAttribute('PlanTrabajo');

                console.log(this.aprobado);

                if(this.planTrabajo.idMateria == null){
                    this.$swal.fire(
                        'Oops...',
                        'Por favor intente nuevamente.',
                        'error'
                      )
                    return;
                }
                else{
                    this.showMateriaDocente(this.planTrabajo);
                }
            });
        }
    },
    components: {
        dataTable,
        Loader,
        PlanTrabajo

    },
    mounted() {
        var usuario = JSON.parse(localStorage.getItem('usuario'));
        if (!usuario) {
            this.$router.push('/Login');
        }
        this.auth=usuario;
        this.getPlanTrabajo();
        this.getEspecialidades();      
        this.getUnidadAcademica();
    }
};
